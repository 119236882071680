






import { Component, Vue } from 'vue-property-decorator'
import { loadingController } from './global-loading-controller'

@Component
export default class extends Vue {
  controller = loadingController
}
